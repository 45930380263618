.body {
  padding:100px 40px;
}

.vid {
  display: inline-block;
  text-align: center !important;
  padding:32px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.vid video {
  max-width: 100%;
  display: block;
  margin:auto;
}

.vid .details {
  display: none;
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 19;
}
.vid:hover .details {
  display: block;
}
.vid .link {
  background-color:#000;
  color:#f2f2f2;
  display: none;
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 12px;
  padding: 3px 9px;
  margin-right: 4px;
  border-radius: 100px;
  font-weight: 600;
  -webkit-border-radius: 100px;
  -moz-border-radius:100px;
  color:rgb(32, 41, 76);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 6px -1px, rgba(0, 0, 0, 0.03) 0px 2px 4px -1px;
  z-index: 19;
}
.vid .link a {
  text-decoration: none;
  color:#f2f2f2;
}
.vid:hover .link {
  display: block;
  opacity: 100%;
  animation: fadeIn 0.2s;
}
.vid .detail {
  display: inline-block;
  font-size: 12px;
  background-color: #fff;
  font-weight: 600;
  color:rgb(32, 41, 76);
  text-transform: capitalize;
  padding: 3px 9px;
  margin-right: 4px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius:100px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 6px -1px, rgba(0, 0, 0, 0.03) 0px 2px 4px -1px;
  border:1px solid #F0F1F5;
  animation: fadeIn 0.2s;
}

.rh5v-Overlay_component {
  display: none !important;
}

.rh5v-Overlay_inner {
  display: none !important;
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}


.overlay {
  position: fixed;
  z-index: 20;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  background-color: #F0F1F5;
  overflow: auto;
}

.infoPanel {
  position:relative;
  z-index: 22;
  background-color: #fff;
  max-width: 500px;
  margin:auto;
  opacity: 1;
  padding:40px;
}
.menuButtons {
  position: fixed;
  top:20px;
  right:40px;
  z-index: 19;
}

.menuButtons .primary {
  background-color: #22294A;
  color:#fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius:100px;
  padding:4px 9px 4px 9px;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 4px 6px -1px, rgba(0, 0, 0, 0.03) 0px 2px 4px -1px;
}
.menuButtons .secondary {
  background-color: #fff;
  color:#22294A;
  font-size: 12px;
  font-weight: 600;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-right: 8px;
  -moz-border-radius:100px;
  padding:4px 9px 4px 9px;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 4px 6px -1px, rgba(0, 0, 0, 0.03) 0px 2px 4px -1px;
  border:1px solid #F0F1F5;
}

.nav {
    position: fixed;
    top:20px;
    left:40px;
    z-index: 19;
}

.nav ul li a {
  text-decoration: none;
  font-size: 13px;
  color: #70789E;
  display: block;
  padding:4px 12px;
  font-weight: 600;
}
.nav ul li a:hover {
  color:#22294A;
}

.nav ul li a.active {
  background-color: #F0F1F5;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius:3px;
  color:#22294A;
}

.szh-menu {
  background-color: #fff;
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  list-style-type: none;
  border:1px solid #F0F1F5;
  padding:12px !important;
  margin:0px !important
}

.szh-menu li {
 margin:0px;
 width: 100%;
}

.szh-menu-button {
    font-size: 12px;
    background-color: #fff;
    font-weight: 600;
    padding:6px 9px 3px 9px;
    color:#20294c;
    text-transform: capitalize;
    margin-right: 4px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius:100px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 4px 6px -1px, rgba(0, 0, 0, 0.03) 0px 2px 4px -1px;
    border:1px solid #F0F1F5;
    cursor: pointer;
}
.szh-menu-button:hover {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -1px;
}

.szh-menu-button svg {
  display: inline-block;
  width: 18px;
  vertical-align: middle;
  margin-top: -2px;
}

.logo {
  margin-right: 6px;
}

h1 {
  font-size: 14px;
  color:#000;
  font-weight: 300;
  display: inline;
}

.logoHeader {
  position: fixed;
  top:16px;
  left:50%;
  margin-left: -16px;
  width: 32px;
  list-style-type: none;
  display: flex;
  z-index: 19;
  justify-content: space-between;
  background-color:rgba(255, 255, 255, 0.30);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 6px -1px, rgba(0, 0, 0, 0.03) 0px 2px 4px -1px;
  border:3px solid #fff;
  backdrop-filter: blur(4px);
  border-radius: 10px;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
}

.react-tooltip {
  max-width: 200px !important;
  background-color: #22294A;
}
.react-tooltip span {
  display: block;
  margin-top: 12px;
}


.overlay .breadCrumb {
  font-size: 14px;
  background-color: #fff;
  font-weight: 600;
  padding:4px 9px 3px 9px;
  color:#20294c;
  text-transform: capitalize;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
  margin-top:30px;
  -moz-border-radius:100px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 4px 6px -1px, rgba(0, 0, 0, 0.03) 0px 2px 4px -1px;
  border:1px solid #F0F1F5;
}

.overlay img {
  margin-top: 20px;
}

.overlay .breadCrumb a {
  color:#70789E;
  text-decoration: none;
}

.overlay .breadCrumb span {
  color:#b1b6d0;
}

.infoPanel a.website {
  display: block;
  text-align: left;
  font-size: 12px;
  color:#22294A;
  font-weight: 600;
  text-decoration: none;
  margin-top: 12px;
}